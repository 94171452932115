import React, {useContext, useState} from "react";
import {navigate} from "gatsby";
import {Button, Form} from "react-bootstrap";
import {MarkdownPageLayout, TermsAndConditionsLabel, TermsAndConditionsModal} from "../../components/components";
import appContent from "../../markdown/app-content";
import UserProfileContext from "../../context/user-profile-context";
import {USER_NAME_CHARACTER_LIMIT} from "../../config/config-options";
import useGameIdFromURLOrPrimaryGame from "../../hooks/useGameIdFromURLOrPrimaryGame";
import ApplyCustomGameTheme from "../../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";
import GameContext from "../../context/game-context";

const SignUpPage = () => {
  const {gameId} = useGameIdFromURLOrPrimaryGame();
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [name, setName] = useState("");
  const {signUpOrUpdateLocalProfile} = useContext(UserProfileContext);

  const navigateToJoinGame = () => {
    const params = new URLSearchParams({gameId: gameId});
    navigate(`/game/join-game?${params.toString()}`);
  };

  const handleOnSignUp = (event) => {
    event.preventDefault();
    signUpOrUpdateLocalProfile({name}).then(() => {
      navigateToJoinGame();
    });
  };

  return <MarkdownPageLayout
    logo="lg"
    markdown={appContent.inGame.signUp.markdown}
    usePrimaryGame
  >
    <Form onSubmit={handleOnSignUp}>
      <Form.Group>
        <Form.Label>{appContent.inGame.signUp.nameLabel}</Form.Label>
        <Form.Control
          type="text"
          placeholder={appContent.inGame.signUp.namePlaceholder}
          required
          value={name}
          maxLength={USER_NAME_CHARACTER_LIMIT}
          onChange={(event) => setName(event.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="checkbox"
          label={<TermsAndConditionsLabel
            content={appContent.inGame.signUp.termsAndConditions}
            onViewTermsAndConditions={() => setShowTermsAndConditions(true)}/>
          }
          required
        />
      </Form.Group>
      <Button className="mt-5" block type="submit">
        {appContent.inGame.signUp.submitText}
      </Button>
    </Form>
    <TermsAndConditionsModal
      show={showTermsAndConditions}
      onHide={() => setShowTermsAndConditions(false)}
    />
    <ApplyCustomGameTheme usePrimaryGame />
  </MarkdownPageLayout>;
};

export default SignUpPage;
